// Body
$body-bg: #f8fafc;
$text-color-default: #212529;
$text-color-white: #f8fafc;
$hover-opacity: .75;
$inactive: #b3b3b3;


// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$logo-1: #35a7b0;
$logo-2: #04AFA7;
$employees: #dd9933;
$complaint: #D0000F;
$good-practices: #4aaa9d;
$providers: #b9a56e;
$stakeholders: #656565;
$help: #D0000F;
$link: #3490dc;

// width
$carousel-width: 350px;
$max-width: 300px;
